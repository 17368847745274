import React, { useState, useEffect } from "react";

import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import loginImage from "../../src/assets/images/BGMai03.jpg";
import crde1 from "../../src/assets/images/Creditscore(1).svg";
import crde2 from "../../src/assets/images/Creditscore(2).svg";
import comp_log from "../../src/assets/images/log_image.png";
import "./dashboard.css";

export default function monitorDashboard(props) {
  

  return (
    <>
     
      <div className="container">
        <div className="background-image">
          <div
            style={{
              backgroundImage: `url(${loginImage})`,
              
            }}
          >
            <div className="button-container">
              
            </div>
          </div>
        </div>
        <div class="power_bi">
          <iframe
            title="GSEP-SchoolsUnderServiceUnit_New"
            width="1000"
            height="500"
            src="https://app.powerbi.com/view?r=eyJrIjoiODA1YzljZmItNzhhNS00OGU2LThhYjEtYmY3ZjNmMzdlNWZkIiwidCI6ImIxZjc3YjY3LWU2NjktNDI1ZS04NTkwLTdhMjBhYjNiYThmMCIsImMiOjEwfQ%3D%3D"
          ></iframe>
        </div>
      </div>
      <section className="footer-wrap">
        <div className="container">
          <div className="copyright">
            <div class="log_img">
              <img
                src={comp_log}
                style={{
                  width: "240px",
                  height: "100px",
                  //   //   padding: "20px",
                  //   marginLeft: "580px",
                  //   marginTop: "80px",
                }}
                class="d-inline-block lg-com-log mr-rt-2"
                alt=""
              />
            </div>
          </div>
          <div>
            
          </div>
        </div>
      </section>
    </>
  );
}
