import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import loginImage from "../../src/assets/images/BGMai1.jpg";
import crde1 from "../../src/assets/images/Creditscore(1).svg";
import crde2 from "../../src/assets/images/Creditscore(2).svg";
import comp_log from "../../src/assets/images/log_image.png";
import "./dashboard.css";

export default function Dashboard(props) {
  const history = useHistory();
  const [accessToken, setGetToken] = useState(undefined);
  const [embededUrl, setEmbededUrl] = useState(false);
  const [serverToken, setServerToken] = useState(undefined);
  const [serverState, setServerState] = useState("");
  const [pageLoad, setPageLoad] = useState(false);

  const MINUTE_MS = 60000;
  const [report, setReport] = useState(null);

  return (
    <>
      {/* <div
        style={{
          backgroundImage: `url(${loginImage})`,
          width: "1340px",
          height: "800px",
        }}
      ></div>
      <div class="">
        <button>Hi </button>
      </div>
      <div></div> */}
      <div className="container">
        <div className="background-image">
          <div
            style={{
              backgroundImage: `url(${loginImage})`,
              //   width: "1340px",
              //   height: "800px",
            }}
          >
            <div className="button-container">
              {/* <button
                class="me-5"
                style={{ borderRadius: "18px", width: "200px" }}
              >
                <img
                  src={crde2}
                  onClick={() => {
                    history.push("/intermediate");
                  }}
                              />
                 <div>  Home Loan Approval</div>           
              </button>
              <button  class="me-5"  style={{ borderRadius: "18px", width: "200px" }}>
                <img src={crde1} />
                <div>Credit Score Monitoring</div>       
              </button> */}
            </div>
          </div>
        </div>
        <div class="power_bi">
          <section>
            <div className="col-12 w-100">
              <iframe
                title="GSEP-SchoolsUnderServiceUnit_New"
                width="1000"
                height="500"
                src="https://app.powerbi.com/view?r=eyJrIjoiMzU3MDYwNjItNTc0NC00MDg2LTgyMTgtZjU3YmM1ODAzMDY3IiwidCI6ImIxZjc3YjY3LWU2NjktNDI1ZS04NTkwLTdhMjBhYjNiYThmMCIsImMiOjEwfQ%3D%3D"
              ></iframe>
            </div>
          </section>
        </div>
      </div>
      <section className="footer-wrap">
        <div className="container">
          <div className="copyright">
            <div class="log_img">
              <img
                src={comp_log}
                style={{
                  width: "240px",
                  height: "100px",
                  //   //   padding: "20px",
                  //   marginLeft: "580px",
                  //   marginTop: "80px",
                }}
                class="d-inline-block lg-com-log mr-rt-2"
                alt=""
              />
            </div>
          </div>
          <div></div>
        </div>
      </section>
    </>
  );
}
