import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./login.css";
import comp_log from "../../src/assets/images/log_image.png";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { showToaster } from "../services/helper";
// import { login } from "../services/api-servics";
import loginImage from "../../src/assets/images/login_image.jpg";
export default function Login() {
  const history = useHistory();
  const handleLogin = () => {
    history.push("/dashboard");
  };

  return (
    <div className="login">
      <section class="hi-100">
        <div class="container-fluid h-custom">
          <div class="row d-flex ">
            <div class="col-md-6 d-flex justify-content-center">
              <img
                style={{ width: "100%", height: "80%" }}
                src={loginImage}
                alt="Login Image"
              />
            </div>
            <div class="col-md-6 d-flex login_form justify-content-center">
              <form>
                <div class="lg-head-div">
                  <h2 class="lg-head ">Log in to your Account</h2>
                  <h4 class="lg-head-sub">Unlock your journey from here</h4>
                </div>

                <div class="col mb-6">
                  <div class="login-form">
                    <div class="material-textfield">
                      <input
                        type="text"
                        id="username"
                        name="username"
                        placeholder=" "
                        class="form-control form-control-lg lg-input osha-text-input"
                      />
                      <label>Email</label>
                    </div>
                  </div>
                </div>

                <div class="col mb-6">
                  <div class="login-form">
                    <div class="material-textfield">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder=" "
                        class="form-control form-control-lg lg-input osha-text-input"
                      />
                      <label>Password</label>
                    </div>
                  </div>
                </div>
                <div class="lg_bottom">
                  <div class="btm_seg">
                    <div>
                     
                      <input type="checkbox" />
                    </div>
                    <div>Remember me</div>
                  </div>
                  <div style={{color:"rgb(19 127 141)",fontWeight:700}}>Forgot Password ?</div>
                </div>

                <div class="text-center text-lg-start mt-4 pt-2 mb-2">
                  <button
                    type="submit"
                    class="lg-btn btn-label"
                    onClick={handleLogin}
                  >
                    LOG IN
                  </button>

                  {/* <p class="small fw-bold mt-2 pt-1 mb-0 lg-signup">
                    Don't have an account?
                    <a href="#" class="link-danger">Sign Up</a>
                  </p> */}
                </div>
                <div class="log_img">
                  <img
                    src={comp_log}
                    style={{
                      width: "80%",
                      height: "40%",
                      padding: "20px",
                      marginLeft: "70px",
                    }}
                    class="d-inline-block lg-com-log mr-rt-2"
                    alt=""
                  />
                </div>
              </form>
            </div>
            {/* <div class="col-md-6"></div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
